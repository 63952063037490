@import '../../resources/sass/abstract/mixins';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0 30px;
  background-color: #fff;
  box-shadow: 0 6px 95px -12px rgba(#000, 0.35);

  @include respond-to(large) {
    height: 90px;
    padding: 0 16px;
  }

  &-logo {
    display: flex;
    min-width: 126px;
    height: 100%;
    padding: 0;

    img {
      max-height: 100%;
    }

    @include respond-to(large) {
      width: 256px;
      padding: 16px;
    }
  }

  &-nav {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    flex: 1 0 auto;
    height: 100%;
    width: 100%;
    padding: 0 30px;
    background-color: #fff;
    z-index: map-get($zindex, page);

    @include respond-to(large) {
      position: relative;
      display: block;
      width: auto;
    }

    .menu-open & {
      display: block;
      z-index: map_get($zindex, overlay);
    }

    &-list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
      list-style: none;

      @include respond-to(large) {
        justify-content: flex-end;
        flex-direction: row;
        height: 100%;
      }
    }

    &-item {
      width: 100%;
      height: 50px;
      font-family: $font-text;
      font-weight: 700;
      color: $blue;
      text-decoration: none;
      white-space: nowrap;

      &.warning {
        color: $red;

        &:hover {
          color: $red-hover;
        }
      }

      @include respond-to(large) {
        width: auto;
        height: auto;
      }

      &:not(:last-child) {
        margin-right: 0;

        @include respond-to(large) {
          margin-right: 16px;
        }
      }

      &:hover {
        color: $orange;
      }

      &.active {
        color: $orange;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: inherit;
      }
    }
  }

  &-meta {
    display: none;
    white-space: nowrap;
    position: relative;
    padding: 0;
    height: 100%;

    @include respond-to(large) {
      display: none;
    }

    @include respond-to(larger) {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 0 30px;
    }

    &::before {
      position: absolute;
      left: 0;
      display: none;
      color: $blue;
      content: '|';

      @include respond-to(large) {
        display: inline;
      }
    }

    .menu-open & {
      position: absolute;
      bottom: 58px;
      z-index: map_get($zindex, background);
      display: block;
      height: 50px;
    }

    &-tel {
      margin-right: 16px;
      font-family: $font-text;
      font-weight: 700;
    }
  }

  &-menu-toggle {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;

    @include respond-to(large) {
      display: none;
    }

    .menu-open & {
      z-index: map_get($zindex, top);
    }
  }
}

$mm-button-width: 40px;
$mm-button-height: 40px;
$mm-icon-line-height: 4px;
$mm-icon-line-spacing: 4px;
$mm-icon-width: 24px;
$mm-icon-height: ($mm-icon-line-height * 3) + ($mm-icon-line-spacing * 2);

.menu {
  position: relative;
  width: $mm-button-width;
  height: $mm-button-height;
  transition: all 0.3s;

  &-icon,
  &-icon::before,
  &-icon::after {
    position: absolute;
    display: block;
    height: $mm-icon-line-height;
    content: '';
    background-color: $blue;
    transition: all 0.3s;
  }

  &-icon {
    top: 50%;
    left: 50%;
    width: $mm-icon-width;
    transform: translate(-50%, -50%);

    &::before,
    &::after {
      left: 0;
      width: $mm-icon-width;
    }

    &::before {
      top: -($mm-icon-line-spacing + $mm-icon-line-height);
    }

    &::after {
      top: $mm-icon-line-spacing + $mm-icon-line-height;
    }

    .menu-open & {
      &::after,
      &::before {
        top: 0;
      }

      &::after {
        transform: rotate(90deg);
      }
    }
  }

  .menu-open & {
    transform: rotate(45deg);
  }
}