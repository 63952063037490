.lazy {
	opacity: 0;
	will-change: opacity;
	transition: ease 0.4s;

	&.lazyloaded {
		opacity: 1;
	}
}

.object-fit {
	object-fit: cover;
	width: 100%;
	height: 100%;
	font-family: 'object-fit: cover'; // initializes lazySizes object-fit extension
}
.background-image {
	height: 100%;
}

.mb-16 {
	margin-bottom: 16px;
}