@import '../../resources/sass/abstract/mixins';

.about-page {
  .practice-intro {
    &-content {
      display: flex;
      justify-content: flex-start;

      @include respond-to('until', medium) {
        flex-direction: column;
      }
    }

    &-picture {
      max-height: 700px;

      @include respond-to(medium) {
        flex: 1 1 100%;
      }
    }

    &-text {
      z-index: map_get($zindex, page);
      flex: 1 1 100%;
      max-height: max-content;
      padding: 20px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0 10px 30px rgba(#000, 0.1);
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: bold;
      }

      @include respond-to(medium) {
        margin: 0;
        padding: 50px;
      }

      h3 {
        margin-bottom: 16px;
      }

      .buttons {
        display: flex;
        flex-direction: column;

        .button {
          margin-right: 5px;

          :last-child {
            margin-right: 0;
          }
        }

        @include respond-to(medium) {
          flex-direction: row;
        }
      }
    }
  }

  .practice {
    position: relative;
    padding-top: 144px;
    overflow: hidden;

    &.bavel {
      background-color: $beige;
      padding-bottom: 40px;

      &::after {
        content: '';
        background-color: $gray;
      }
    }

    &.dorst {
      background-color: $gray;

      &::after {
        content: '';
        background-color: #fff;
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      top: -125px;
      right: 0;
      width: 100%;
      height: 200px;
      transform: skewY(-4deg);
    }

    &-info {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;

      @include respond-to(large) {
        flex-wrap: nowrap;
      }

      &-content {
        margin: 0 0 20px 0;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 10px 30px rgba(#000, 0.1);

        @include respond-to(medium) {
          margin: 0 20px 0 0;
        }
      }

      &-aside {
        flex: 0 0 100%;
        border-radius: 5px;
        background-color: $faded-blue;
        overflow: hidden;
        box-shadow: 0 10px 30px rgba(#000, 0.1);

        @include respond-to(medium) {
          flex: 0 0 300px;
        }
      }

      &-address {
        padding: 30px;
      }

    }

    .practice-image img {
      border-radius: 5px;
    }
  }

  .quick-information {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 24px 16px;
    border-radius: 5px;
    background-color: #fff;
    transform: translateY(-40px);
    box-shadow: 0 10px 30px rgba(#000, 0.1);

    @include respond-to(large) {
      padding: 68px 0 68px 100px;
    }

    &-content {
      width: 100%;
      margin-bottom: 20px;

      @include respond-to(medium) {
        width: 50%;
      }
    }

    &-title {
      margin-bottom: 60px !important;
    }

    &-block {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @include respond-to(medium) {
        width: 50%;
      }

      &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 26px;
        background-color: $faded-blue;
        box-shadow: 0 10px 30px rgba(#000, 0.1);

        @include respond-to(medium) {
          max-width: 300px;
          padding: 46px;
        }

        &-item {
          width: 100%;
          margin-bottom: 30px;
          padding: 20px 0;
        }

        &-title {
          margin-bottom: 20px;
          color: #fff;
        }

        &-text {
          color: #fff;

          a {
            color: #fff;
          }
        }
      }
    }
  }

}