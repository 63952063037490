@import '../abstract/mixins';

.button {
  display: inline-block;
  height: 50px;
  padding: 0 30px;
  border-radius: 5px;
  font-family: $font-text;
  text-align: center;
  line-height: 50px;
  color: #fff;

  &.blue {
    background-color: $blue;

    &:hover {
      background-color: $orange;
      color: #fff;
    }
  }

  &.orange {
    background-color: $orange;

    &:hover {
      background-color: darken($orange, 10%);
      color: #fff;
    }
  }

  &.full-mobile {
    width: 100%;
    margin-bottom: 10px;

    @include respond-to(medium) {
      width: auto;
      margin-bottom: 0;
    }
  }
}