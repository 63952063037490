// Breakpoints
$breakpoints: (
        'smallest': 320px,
        'smaller':  360px,
        'small':    420px,
        'medium':   768px,
        'large':    1024px,
        'larger':   1120px,
        'huge':     1200px,
        'xhuge':    1400px,
);

$zindex: (
        'background':   1,
        'modal':        2,
        'overlay':      5,
        'top':          10
);

// Fonts
$font-heading: 'Roboto Slab', serif;
$font-text: 'Sintony', sans-serif;

// Colors
$blue: #445586;
$faded-blue: #5b6077;
$orange: #e59246;
$beige: #e6c7a3;
$gray: #f4f4f4;
$red: #d32121;
$red-hover: #ff0000;
$white: #fff;