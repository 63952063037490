@import '~slick-carousel/slick/slick';
@import '../abstract/mixins';

.slick {
  &-list {
    padding: 50px !important;
  }

  &-track {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &-slide[aria-hidden=true] {
    opacity: 0.4;
    transition: opacity 0.4s;

    @include respond-to(large) {
      opacity: 0;
    }
  }
}

.slick-arrow {
  position: absolute;
  top: 50%;
  left: 2px;
  z-index: map_get($zindex, modal);
  margin-top: -25px;
  text-indent: -9999px;

  @include respond-to(large) {
    left: -40px;
  }
}

.slick-prev {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 30px 20px 0;
  border-color: transparent #ffffff transparent transparent;
}

.slick-next {
  right: 2px;
  left: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 30px;
  border-color: transparent transparent transparent #ffffff;

  @include respond-to(large) {
    right: -40px;
  }
}

.slick-disabled {
  pointer-events: none;
  opacity: 0.3;
}

.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  margin: 16px 0 0;
  list-style: none;

  li {
    width: 12px;
    height: 12px;
    margin: 0 6px;
    transition: 0.3s;

    &.slick-active {
      width: 30px;
    }
  }

  button {
    display: block;
    width: 100%;
    height: 100%;
    text-indent: -9999px;
    background-color: #000;
    border-radius: 10px;

    @at-root .slick-active button,
    &:hover {
      background-color: #000;
    }
  }
}
