.patient-portal-page {
  .pp-faq {
    ul {
      list-style-position: inside;
    }

    section {
      h3 {
        font-weight: bold;
      }

      h4 {
        font-style: italic;
      }

      margin: 10px 0;
    }
  }

  .notice {
    padding-top: 20px;
  }

  .entry-bottom {
    margin-bottom: 64px;
  }
}