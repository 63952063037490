@import '../../resources/sass/abstract/mixins';
@import '../../resources/sass/abstract/variables';

.footer {
  background-color: $faded-blue;

  .menu-open & {
    display: none;
  }

  &-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 22px 10px;

    & .center {
      display: flex;
      flex: 1 1 0;
      justify-content: center;

      &.copyright {
        font-size: 12px;
      }
    }

    @include respond-to(medium) {
      &-top {
        padding: 40px;
      }

      padding: 20px 40px;
    }

    .logo {
      display: flex;
      justify-content: center;

      img {
        height: 150px;
        margin: 0 16px;
      }
    }
  }

  &-item {
    min-width: 200px;
    margin: 10px 10px;

    h4 {
      margin-bottom: 20px;
      font-weight: 700;
      color: $white;
    }

    &-list {
      list-style: none;

      &-item {
        line-height: 25px;
        color: $white;
      }

      &-link {
        color: $white;
      }
    }
  }

  .map {
    width: 300px;
  }
}