@import '../../resources/sass/abstract/mixins';

.employees-page {

  .employees-intro {
    margin-bottom: 80px;

    &-wrapper {
      position: relative;
      max-width: 737px;
      margin-top: -90px;
      box-shadow: 0 10px 30px rgba(#000, 0.1);

      @include respond-to(medium) {
        margin-top: -120px;
      }
    }

    &-header {
      height: 90px;
      padding: 20px 0 0 40px;
      border-radius: 5px 5px 0 0;
      background-color: rgba(255, 255, 255, 0.4);

      @include respond-to(medium) {
        height: 120px;
      }

      h2 {
        color: #fff;
      }
    }

    &-content {
      padding: 20px;
      border-radius: 0 0 5px 5px;
      background-color: #fff;

      @include respond-to(medium) {
        padding: 40px;
      }
    }
  }
}

.employees {
  padding: 50px 0;
  background-color: #eee;

  &-slider-wrapper {
    margin: 0;

    @include respond-to(large) {
      margin: 0 100px;
    }

    & h2 {
      font-size: 42px;
    }
  }
}