@import '../../resources/sass/abstract/mixins';

.news-page {
  .news {

    &-items {
      margin: 0 -5px;

      @include respond-to(large) {
        margin: 0 -25px;
      }

      &-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
      }
    }
  }
}