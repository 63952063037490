@import '../../resources/sass/abstract/mixins';

.employee-information {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  margin-bottom: 24px;
  border-radius: 5px;
  background-color: #ffffff;

  @include respond-to(small) {
    flex-wrap: nowrap;
  }

  &-image {
    padding: 24px 0;
    flex: 1 0 auto;
    overflow: hidden;
    width: 200px;
    height: 350px;

    img {
      border-radius: 5px;
    }

    @include respond-to(small) {
      flex: 0 0 200px;
    }
  }

  &-content {
    flex: 0 1 auto;
    padding: 16px;

    @include respond-to(medium) {
      padding: 24px;
    }

    h4 {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 700;
      color: $orange;
    }

    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 700;
      color: $faded-blue;
    }
  }
}