@import '../abstract/variables.scss';

form {
  .field-row {
    display: flex;
    flex-wrap: wrap;
  }

  .field-group {
    flex: 1 1 auto;
    margin-bottom: 24px;

    &:not(last-child) {
      margin-right: 16px;
    }

    label {
      display: block;
      margin-bottom: 16px;
    }

    input,
    textarea {
      width: 100%;
      padding: 0 16px;
      border: 1px solid $gray;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0 1px 2px rgba(#000, 0.1);
    }

    input {
      height: 50px;
    }

    textarea {
      padding: 16px;
    }
  }

  input[type=submit] {
    border-radius: 5px;

    &:hover {
      background-color: darken($faded-blue, 10%) !important;
    }
  }
}

.contact-form {
  margin: 40px 0;
  padding: 26px;
  background-color: $orange;
  border-radius: 5px;
}