@import '../../resources/sass/abstract/mixins';

.news-item {
  flex: 1 1 100%;
  padding: 5px;

  @include respond-to(large) {
    padding: 16px;
  }

  &-content {
    background-color: #fff;
    box-shadow: 0 5px 16px rgba(#000, 0.1);

    padding: 30px;
    @include respond-to(large) {
      padding: 40px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h4 {
        font-weight: bold;
      }
    }

    &-date {
      opacity: 0.5;
      font-size: 14px;
    }

    &-image {
      display: flex;
      justify-content: center;
      margin: 6px 0;

      img {
        @include respond-to('until', medium) {
          max-width: 250px;
        }
        max-height: 250px;
      }
    }

    &-text {
      p {
        margin: 10px 0;
      }

      ul, ol {
        margin: 0 24px;
      }
    }
  }

}