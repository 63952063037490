@import '../../resources/sass/abstract/mixins';

.employees-slider-slide {

  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  transition: transform 0.4s, opacity 0.2s;
  box-shadow: 0 10px 30px rgba(#000, 0.2);


  .slick-slide & {
    z-index: map_get($zindex, background);
    height: 500px;
    padding: 20px;
  }

  .slick-current & {
    z-index: map_get($zindex, modal);
    transform: scale(1.1);
  }

  &-content {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: map_get($zindex, modal);
    padding: 20px;
    color: #fff;
    background-color: rgba(68, 85, 134, 0.51);
    width: 100%;

    h4 {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 700;
      color: #fff;
    }

    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 700;
      color: $beige;
    }
  }

  &-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: map_get($zindex, background);
  }
}