@import '../abstract/mixins';

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.main {
  flex: auto;
  z-index: map_get($zindex, modal);

  .menu-open & {
    display: none;
  }
}

.flex {
  width: calc(100% - 32px);
  margin-right: auto;
  margin-left: auto;

  @include respond-to(large) {
    width: calc(100% - 64px);
  }
}

.content {
  max-width: 940px;
  margin-right: auto;
  margin-left: auto;
}

.entry {
  padding: 64px 0;

  @include respond-to(large) {
    padding: 120px 0;
  }
}

.entry-bottom {
  padding-bottom: 32px;
  margin-bottom: 32px;

  @include respond-to(medium) {
    padding-bottom: 48px;
    margin-bottom: 48px;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.latest-news {
  &-title {
    display: flex;
    justify-content: center;
    padding: 40px 0;
  }
}