@import './variables';

@mixin respond-to($breaktype, $breakpoint: false) {
  $breakpointMin: map-get($breakpoints, $breaktype);
  $breakpointMax: map-get($breakpoints, $breakpoint);

  @if ($breaktype == 'until') {
    @media(max-width: ($breakpointMax - 1)) {
      @content;
    }
  }	@else if ($breakpoint == false) {
    @media(min-width: $breakpointMin) {
      @content;
    }
  }	@else if type-of($breaktype) == 'string' {
    @media(min-width: $breakpointMin) and (max-width: $breakpointMax) {
      @content;
    }
  }	@else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

@each $key, $breakpoint in $breakpoints {
  @include respond-to($key) {
    .hide-from-#{$key} {
      display: none !important;
    }
  }
}

@each $key, $breakpoint in $breakpoints {
  @include respond-to('until', $key) {
    .hide-until-#{$key} {
      display: none !important;
    }
  }
}