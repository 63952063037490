@import '../abstract/mixins';

body {
  font-family: $font-text;
}

a {
  text-decoration: none;
  color: $blue;

  &:hover {
    color: $orange;
  }
}

h2 {
  margin-bottom: 20px;
  font-family: $font-heading;
  font-size: 32px;
  color: #000;

  @include respond-to(large) {
    font-size: 42px;
  }

  &.has-line {
    position: relative;
    margin-bottom: 48px;

    &::after {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 100px;
      height: 8px;
      background-color: $orange;
    }
  }
}

p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 25px;

  &:last-child {
    margin-bottom: 0;
  }
}