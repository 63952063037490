.privacy-page {
  section {

    .content-list {
      padding: 8px 0;
    }

    .list-header {
      line-height: 25px;
    }

    ul {
      list-style-position: inside;

      li {
        line-height: 25px;
      }
    }

    h3 {
      font-weight: bold;
      margin-bottom: 4px;
    }

    &.entry-top {
      padding: 120px 0 32px 0;
    }

    &.entry {
      padding: 32px 0 120px 0;
    }
  }
}