@import '../abstract/mixins';

.hero {
  &-wrapper {
    position: relative;
    height: calc(100vh - 60px);

    @include respond-to(medium) {
      height: calc(100vh - 90px);
    }
  }

  &-image {
    height: 100%;

    img {
      object-position: 70% 50%;

      @include respond-to(small) {
        object-position: 60% 50%;
      }

      @include respond-to(large) {
        object-position: 50% 50%;
      }
    }
  }

  &-content {
    position: absolute;
    top: 10%;
    left: 0;
    z-index: map_get($zindex, background);
    width: 100%;
    max-width: 700px;
    margin: 30px;
    padding: 20px;
    border-left: 8px solid $orange;
    color: #fff;
    background: linear-gradient(to right, rgba(#858585,0.5) 0%, rgba(#fff , 0) 100%);
    transform: translate(0, -50%);

    @include respond-to(medium) {
      top: 50%;
      left: 50%;
      padding: 40px;
      background: linear-gradient(to right, rgba(#fff ,0.5) 0%, rgba(#fff , 0) 100%);
      transform: translate(-60%, -50%);
    }
  }

  &-title {
    margin-bottom: 12px;
    font-family: $font-heading;
    font-size: 30px;

    @include respond-to(medium) {
      font-size: 70px;
    }
  }

  &-subtitle {
    font-size: 16px;
    font-weight: 700;
  }
}