@import '../../resources/sass/abstract/mixins';

.home-page {
  .quick-information {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 24px 16px;
    border-radius: 5px;
    background-color: #fff;
    transform: translateY(-40px);
    box-shadow: 0 10px 30px rgba(#000, 0.1);

    @include respond-to(large) {
      padding: 68px 0 68px 100px;
    }

    &-content {
      width: 100%;
      margin-bottom: 20px;

      @include respond-to(medium) {
        width: 50%;
      }
    }

    &-title {
      margin-bottom: 60px !important;
    }

    &-block {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @include respond-to(medium) {
        width: 50%;
      }

      &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 26px;
        background-color: $faded-blue;
        box-shadow: 0 10px 30px rgba(#000, 0.1);

        @include respond-to(medium) {
          max-width: 300px;
          padding: 46px;
        }

        &-item {
          width: 100%;
          margin-bottom: 30px;
          padding: 20px 0;
        }

        &-title {
          margin-bottom: 20px;
          color: #fff;
        }

        &-list {
          margin: 20px 0;
          list-style: none;
          color: #fff;

          .text-indented {
            text-indent: -4em;
            margin-left: 4em;
          }

          &-item {
            margin-top: 6px;
          }
        }

        &-text {
          color: #fff;

          a {
            color: #fff;
          }
        }
      }
    }
  }
}

.showcase {
  position: relative;
  overflow: hidden;

  &::before {
    display: block;
    width: 100%;
    padding-top: 43.75%;
    content: '';
  }

  &-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;

    &:first-child {
      width: calc(100% / 3 + 5vw);
      padding: 16px;
    }

    &:nth-child(2) {
      z-index: map_get($zindex, top);
      width: calc(100% / 3 - 10vw);

      .showcase-item-background {
        top: 0;
        right: -10vw;
        bottom: 0;
        left: -10vw;
        clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
      }
    }

    &:nth-child(3) {
      width: calc(100% / 3 + 5vw);
      padding: 16px;
    }

    &-background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: map_get($zindex, background);
      overflow: hidden;
      pointer-events: none;
      background: no-repeat 0/cover;
    }
  }
}
